import React from "react";
import isAfter from "date-fns/isAfter";

import { default as _get } from "lodash/get";
import { default as _has } from "lodash/has";

import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import { getClassName } from "../components/0-electrons/css";
// import ImageText from "../components/2-molecules/ImageText/ImageText";
import RichText from "../components/2-molecules/RichText/RichText";
import { CardGroup } from "../components/3-organisms/CardGroup/CardGroup";
import { TeaserGroup } from "../components/3-organisms/TeaserGroup/TeaserGroup";
import { TeaserBigGroup } from "../components/3-organisms/TeaserBigGroup/TeaserBigGroup";
import { Button } from "../components/1-atoms/Button/Button";

import { getRootUri } from "../helper/uri";
import { mapNodesToPosts } from "../contentful/helper";
import {
  parseCardTeaserData,
  mapTeaserDataToCard,
} from "../contentful/ContentfulBlogTeaser";
import ContentfulSliderCards from "../contentful/ContentfulSliderCards";
import ContentfulStageVideo from "../contentful/ContentfulStageVideo";
import ContentfulTeaserBig from "../contentful/ContentfulTeaserBig";

import * as css from "./index.module.scss";
// @ts-ignore
import hautstarkVideoPoster from "./hautstark-video-poster.jpg";

export default function Home(): JSX.Element {
  const data = useStaticQuery(graphql`
    query {
      stageS: file(relativePath: { eq: "hautstark-stage-s.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            height: 540
            width: 720
            quality: 80
          )
        }
      }
      stageL: file(relativePath: { eq: "hautstark-stage-l.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1440
            aspectRatio: 1.5
            quality: 80
          )
        }
      }
      # Video
      video: contentfulComponentStageVideo {
        ...FragmentContentfulStageVideo
      }
      # Teaser: cards
      teaserCards: allContentfulEntry(
        filter: {
          contentful_id: {
            in: [
              "6fIy86LU7ZGFAeIQDumnPu"
              "2xZAKxajk0gLTocgFL34ss"
              "sY8E7HO9dKYkZICt1LHJJ"
            ]
          }
        }
      ) {
        edges {
          node {
            ... on ContentfulCategoryPage {
              ...TeaserCategoryPage
            }
            ... on ContentfulBlogPost {
              ...TeaserBlogPost
            }
          }
        }
      }
      # Teaser Big: Hauttest
      teaserSkinTest: contentfulComponentTeaserBig(
        id: { eq: "3ad6f170-d4d0-526d-90c2-50774a189190" }
      ) {
        ...FragmentContentfulTeaserBig
      }
      # Teaser Big: Content Pages
      teaserBig: allContentfulEntry(
        filter: {
          contentful_id: {
            in: [
              "4vOAzrGvssBQCBPdumAWXq" # Mission
              "3eryyM1D33Zht1IbwIq0pH" # Neurodermitis-Behandlung: So gehst du sie erfolgreich an
              "1QsX5H0Mm38CptpQxQx8fS" # Neurodermitis Verstehen
              "sY8E7HO9dKYkZICt1LHJJ" # Leben mit Neurodermitis
            ]
          }
        }
      ) {
        edges {
          node {
            ... on ContentfulCategoryPage {
              ...TeaserCategoryPage
            }
            ... on ContentfulBlogPost {
              ...TeaserBlogPost
            }
          }
        }
      }
      teaserBigImageMission: contentfulAsset(
        contentful_id: { eq: "7KhfzHzfdE8Uhi0fNLwgkx" }
      ) {
        gatsbyImageData(
          placeholder: BLURRED
          width: 700
          # 4:3
          aspectRatio: 1.33
        )
      }
      teaserBigImageLeben: contentfulAsset(
        contentful_id: { eq: "3LyAEH7OxnfnWSiwQwWfL5" }
      ) {
        gatsbyImageData(
          placeholder: BLURRED
          width: 700
          # 4:3
          aspectRatio: 1.33
        )
      }
      teaserBigImageBehandeln: contentfulAsset(
        contentful_id: { eq: "doQl6GbmY7c8rRKk2wt15" }
      ) {
        gatsbyImageData(
          placeholder: BLURRED
          width: 700
          # 4:3
          aspectRatio: 1.33
        )
      }
      teaserBigImageVerstehen: contentfulAsset(
        contentful_id: { eq: "4n63b5RZS3MpiiUjpRT2Yp" }
      ) {
        gatsbyImageData(
          placeholder: BLURRED
          width: 700
          # 4:3
          aspectRatio: 1.33
        )
      }
      # Slider Therapy Cards
      sliderTherapy: contentfulComponentCardSlider(
        id: { eq: "69962b6b-3f94-5472-862a-b9d08858a307" }
      ) {
        ... on ContentfulComponentCardSlider {
          ...FragmentContentfulSliderCards
        }
      }
    }
  `);

  return (
    <>
      <GatsbySeo
        title="#hautstark - in jeder Phase der Neurodermitis"
        description="Neurodermitis ist nicht immer gleich. Damit du in jeder Phase weißt, wie du dir und deiner Haut helfen kannst, erfährst du hier mehr"
        linkTags={[
          {
            rel: "preload",
            as: "image",
            href: hautstarkVideoPoster,
            key: "hautstark-video",
          },
        ]}
      />

      <ContentfulStageVideo content={_get(data, "video", undefined)} />
      <RichText>
        <p>
          Mehr als 4 Millionen Menschen in Deutschland leben mit Neurodermitis.
          Vielleicht gehörst auch du dazu oder jemand aus deinem nächsten
          Umfeld?
        </p>
        <p>
          <strong>
            hautstark.com soll dich im täglichen Umgang mit Neurodermitis
            unterstützen und stärken: von wissenswert für die Behandlung bis
            wertvoll für deinen Alltag.
          </strong>
        </p>
      </RichText>

      <RichText headline="Schau dich um" tag="h2">
        <p>
          Auf hautstark.com findest du ausführliche Infos, um die Hautkrankheit
          rundum zu verstehen, Hilfestellungen, um Symptome zu behandeln und
          außerdem Geschichten, Strategien und Tipps, um im Alltag leichter mit
          Neurodermitis leben zu können.
        </p>
      </RichText>
      <CardGroup
        background={true}
        cards={mapNodesToPosts(_get(data, "teaserCards.edges", [])).map(
          (item: any) => {
            return mapTeaserDataToCard(parseCardTeaserData(item));
          }
        )}
      />
      <RichText headline="In welcher Phase befindet sich deine Haut?" tag="h2">
        <p>
          Neurodermitis ist nicht immer gleich: Die chronische Erkrankung ist
          zwar andauernd da, aber mal mehr und mal weniger spür- und sichtbar.
          Grund dafür ist der Wechsel zwischen schubfreien Phasen und akuten
          Schüben.
        </p>
        <p>
          <strong>
            Erfahre hier mehr über die verschiedenen Phasen und ihre Behandlung:
          </strong>
        </p>
      </RichText>
      <TeaserGroup
        content={[
          {
            href: "neurodermitis/verlauf/keine-schuebe/",
            image: (
              <StaticImage
                src="../images/keine-schuebe-trockene-haut-detail.jpg"
                placeholder="none"
                width={160}
                height={160}
                layout="fixed"
                alt="Keine Schübe, sehr trockene Haut"
              />
            ),
            headline: "Keine Schübe, Sehr trockene Haut?",
            textLink: "Mehr darüber erfahren",
            children:
              "Versorge deine Haut auch in den erscheinungsfreien Phasen regelmäßig. Mit der richtigen Pflege kannst du das Jucken bei trockener Haut lindern und den nächsten Schub hinauszögern, um ihm so ein wenig Wind aus den Segeln zu nehmen.",
          },
          {
            href: "neurodermitis/verlauf/leichte-schuebe/",
            image: (
              <StaticImage
                src="../images/leichte-schuebe-juckreiz-detail.jpg"
                placeholder="none"
                width={160}
                height={160}
                layout="fixed"
                alt="Leichte Schübe, Juckreiz"
              />
            ),
            headline: "Leichte Schübe, Juckreiz?",
            textLink: "Mehr darüber erfahren",
            children:
              "Auf erscheinungsfreie Phasen folgt in der Regel der nächste Schub – aber mit unterschiedlicher Ausprägung: Bei leichter Intensität geht’s vor allem darum, deine Haut zu beruhigen und die Entzündung zu mildern, damit sie nicht heftiger wird.",
          },
          {
            href: "neurodermitis/verlauf/mittelschwere-schuebe/",
            image: (
              <StaticImage
                src="../images/mittelschwere-schuebe-ekzeme-detail.jpg"
                placeholder="none"
                width={160}
                height={160}
                layout="fixed"
                alt="Mittelschwere Schübe, Ekzeme"
              />
            ),
            headline: "Mittelschwere Schübe, Ekzeme?",
            textLink: "Mehr darüber erfahren",
            children:
              "Bei sichtbaren Ekzemen, wunder Haut und anhaltendem Juckreiz – kurzum einem Schub mit mittlerer Intensität – braucht deine Haut auch intensive Hilfe. Lass dich unbedingt ärztlich beraten, um die richtige Therapieform zu finden.",
          },
        ]}
      />
      <ContentfulTeaserBig content={_get(data, "teaserSkinTest", undefined)} />
      <RichText headline="Die passende Pflege in jeder Phase" tag="h2">
        <p>
          Da deine Haut in den verschiedenen Phasen der Neurodermitis
          unterschiedliche Bedürfnisse hat, sollte auch die Pflege an die
          entsprechende Phase angepasst sein. Erfahre hier, welche Produkte dir
          dabei helfen, die Haut optimal in ihrem akuten Zustand zu
          unterstützen.
        </p>
      </RichText>
      <ContentfulSliderCards content={_get(data, "sliderTherapy", undefined)} />
      <TeaserBigGroup
        startPosition="right"
        content={mapNodesToPosts(_get(data, "teaserBig.edges", [])).map(
          (teaser: any) => {
            const imagePath = "heroImage";

            let image = _get(
              teaser,
              `${imagePath}.image.gatsbyImageData`,
              null
            );
            const imageAlt = _get(teaser, `${imagePath}.title`, "");

            // adjust images
            switch (teaser.id) {
              case "77d73677-d851-5dbc-8fea-04e31fcb58e2": // "Mission"
                image = _get(
                  data,
                  "teaserBigImageMission.gatsbyImageData",
                  null
                );
                break;
              case "f1e2abba-0ad8-5ef4-9279-0680942d3f38": // "Neurodermitis behandeln"
                image = _get(
                  data,
                  "teaserBigImageBehandeln.gatsbyImageData",
                  null
                );
                break;
              case "0e8542bb-b480-54cf-8246-efe01840621c": // "Leben mit Neurodermitis"
                image = _get(data, "teaserBigImageLeben.gatsbyImageData", null);
                break;
              case "f75e09b4-3c79-52e9-81d1-1fd1736ff457": // "Neurodermitis Verstehen"
                image = _get(
                  data,
                  "teaserBigImageVerstehen.gatsbyImageData",
                  null
                );
                break;
            }
            const hasImage = _has(image, "images");

            return {
              button: <Link to={getRootUri(teaser.slug)}>Mehr erfahren</Link>,
              image: hasImage ? (
                <GatsbyImage image={image} alt={imageAlt} />
              ) : (
                <StaticImage
                  alt=""
                  src="../images/hautstark-video-poster.jpg"
                  width={700}
                  aspectRatio={4 / 3}
                />
              ),
              headline:
                teaser.id === "f1e2abba-0ad8-5ef4-9279-0680942d3f38" // "Neurodermitis-Behandlung: So gehst du sie erfolgreich an"
                  ? "Neurodermitis behandeln"
                  : teaser.title,
              textLink: "Mehr erfahren",
              children: _get(teaser, "intro.childMarkdownRemark.excerpt", null),
            };
          }
        )}
      />
      {/* follow-me-module and intro */}
      <RichText>
        <p>
          Gefällt dir, was du liest? Folge uns über die üblichen Kanäle und
          bleib auf dem Laufenden. Genauso freuen wir uns, wenn auch du die
          Botschaft #hautstark weiterverbreitest und mit anderen teilst. Damit
          aus „stark bei Neurodermitis“ wirklich „gemeinsam stark bei
          Neurodermitis“ werden kann.
        </p>
      </RichText>
      <div className={getClassName(css, "Home__social-networks")}>
        <Button
          className={getClassName(css, "Home__social-networks-item")}
          icon="socialInstagram"
          iconOnly={true}
          type="primary"
          animation="disabled"
          href="https://www.instagram.com/wir.sind.hautstark/"
          target="_blank"
          rel="noreferrer noopener"
        />
        <Button
          className={getClassName(css, "Home__social-networks-item")}
          icon="socialFacebook"
          iconOnly={true}
          type="primary"
          animation="disabled"
          href="https://www.facebook.com/wir.sind.hautstark"
          target="_blank"
          rel="noreferrer noopener"
        />
        <Button
          className={getClassName(css, "Home__social-networks-item")}
          icon="socialYoutube"
          iconOnly={true}
          type="primary"
          animation="disabled"
          href="https://www.youtube.com/channel/UCUeIZg8BgSSDLOQLogSjKQA"
          target="_blank"
          rel="noreferrer noopener"
        />
      </div>
    </>
  );
}
