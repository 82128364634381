import React from "react";

import { getClassName } from "../../0-electrons/css";
import TeaserBig from "../../2-molecules/TeaserBig/TeaserBig";

import * as css from "./TeaserBigGroup.module.scss";

export interface TeaserBigGroupProps {
  /** The content to Display */
  content?: Array<any>;
  /** The startPosition */
  startPosition?: "left" | "right";
}

export const TeaserBigGroup: React.FC<TeaserBigGroupProps> = ({
  content,
  startPosition = "left",
}: TeaserBigGroupProps) => {
  const TeaserBigItems = content.map((item, i) => (
    <TeaserBig
      key={i}
      image={item.image}
      headline={item.headline}
      headlineTag="h2"
      button={item.button}
      className={getClassName(css, "TeaserBigGroup__item")}
      teaserPosition={
        i % 2 === 0
          ? startPosition
          : startPosition === "left"
          ? "right"
          : "left"
      }
    >
      {item.children}
    </TeaserBig>
  ));

  return (
    <section className={getClassName(css, "TeaserBigGroup")}>
      {TeaserBigItems}
    </section>
  );
};

export default TeaserBigGroup;
